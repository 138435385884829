<template>
  <div class="form-box">
    <FormTitle :infoName="infoName" />
    <div class="form-list">
      <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="创作思想汇报">
          <el-input v-model="ruleForm.title"
                    type="text"
                    disabled
                    placeholder="思想汇报"></el-input>
        </el-form-item>
        <el-form-item label="汇报主题">
          <el-input v-model="ruleForm.extParam.theme"
                    type="text"
                    placeholder="请输入汇报的主题"
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>
        <div class="form-tip-btn">
          <h4>推荐主题：</h4>
          <ul>
            <li v-for="(item,index) in preference"
                :class="item.active === 1 ? 'active' : ''"
                @click="btnPf(index)">{{item.name}}</li>
          </ul>
        </div>
      </el-form>
    </div>
    <div class="form-btn">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <NoticeWrapper />
  </div>
</template>

<script>
  import CommonApi from "@/api/common"
  import { orderMixin } from '@/utils/orderMixin'
  import FormTitle from "@/components/FormTitle/index.vue"
  import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
  export default {
    components: {NoticeWrapper, FormTitle},
    mixins: [orderMixin],
    props: ['infoName'],
		data() {
			return {
        ruleForm: {
          title: '思想汇报',
          creationDocId: 11,
          creationDocCode: 'THOUGHT_REPORT',
          channelCode: null,
          extParam: {
            version: 1,
            theme: '',
            themeType: '通用'
          }
        },
        // rules: {
        //   title: [
        //     { required: true, message: '请输入思想汇报标题', trigger: 'blur' }
        //   ],
        //   extParam: {
        //     theme: [
        //       { required: true, message: '请输入汇报主题', trigger: 'blur' }
        //     ]
        //   }
        // },
        btnInfo: {
          title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
          checked: true
        },
        preference: [{
          active: 0,
          name: '重要讲话'
        },{
          active: 0,
          name: '党的中心任务'
        },{
          active: 0,
          name: '红色电影'
        },{
          active: 0,
          name: '党的基本路线'
        },{
          active: 0,
          name: '时政热点'
        },{
          active: 0,
          name: '二十届三中全会'
        }],
        paramsData: '', // 储存跳页面的传值
			}
		},
		created() {},
		methods: {
      btnPf(index){
        this.preference.forEach(item => {
          item.active = 0
        })
        this.preference[index].active = 1
        this.ruleForm.extParam.theme = this.preference[index].name
        // if(index === 0){
        //   this.ruleForm.extParam.theme = ''
        // }else{
        //
        // }
      },
      submitForm(formName) {
        let _this = this

        if(_this.ruleForm.extParam.theme === ''){
          return _this.$message({
            message: '请输入汇报主题',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }

        _this.paramsData = _this.ruleForm
        let info = JSON.stringify(_this.ruleForm.extParam)
        _this.ruleForm.extParam = info
        //生成订单 mixin
        _this.createOrder(_this.ruleForm,_this.paramsData)

        // this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     _this.paramsData = _this.ruleForm
        //     let info = JSON.stringify(_this.ruleForm.extParam)
        //     _this.ruleForm.extParam = info
        //     //生成订单 mixin
        //     _this.createOrder(_this.ruleForm,_this.paramsData)
        //   } else {
        //     return false;
        //   }
        // });
      },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // }
		}
	}
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
.form-tip-btn{
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  h4{
    width: 80px;
    line-height: 28px;
    margin-right: 10px;
    font-weight: inherit;
  }
  ul{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    li{
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      margin: 0 0 10px 10px;
      border-radius: 60px;
      font-size: 12px;
      color: #3c3c3c;
      background-color: #f7f8fa;
      cursor: pointer;
    }
    li:hover,li.active{
      color: #ffffff;
      background-color: #3575fd;
    }
  }
}
@media screen and (max-width: 900px) {
  .form-tip-btn{
    flex-direction: column;
  }
  .form-tip-btn ul li{
    margin: 10px 10px 0 0;
    background-color: #e5eeff;
  }
}
</style>
